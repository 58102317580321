export const dataFieldValueContainsFilter = ({
    key,
    target = 'product',
    matchType = 'Any',
    value,
    equals = false,
}: {
    /**
     * Key of the data field
     */
    key: string;
    /**
     * Target product or content
     */
    target: 'product' | 'content';
    /**
     * Match all conditions or any
     */
    matchType: 'All' | 'Any';
    /**
     * Value or values to match
     */
    value: string[];
    /**
     *  true if strict comparisson or false if contains.
     */
    equals: boolean;
}) => {
    const conditionType = equals
        ? 'Relewise.Client.Requests.Conditions.EqualsCondition, Relewise.Client'
        : 'Relewise.Client.Requests.Conditions.ContainsCondition, Relewise.Client';
    const targetType = target == 'product' ? 'ProductDataFilter' : 'ContentDataFilter';
    return {
        $type: `Relewise.Client.Requests.Filters.${targetType}, Relewise.Client`,
        key: key,
        filterOutIfKeyIsNotFound: true,
        mustMatchAllConditions: false,
        conditions: {
            items: value.map((item) => ({
                $type: conditionType,
                value: {
                    type: 'String',
                    value: item,
                },
                valueCollectionEvaluationMode: matchType,
                negated: false,
            })),
        },
        negated: false,
    };
};
