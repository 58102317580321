import { create } from 'zustand';
import { DropdownItem } from '~/shared/components';
import {
    Facet,
    Pagination,
    Product,
    ProductStoreStore,
    SortFacetsType,
    SortType,
} from '../../model';
import { DEFAULT_ITEMS_PER_PAGE } from '../../constants';
import { RelewiseContentResult } from '$templates/blocks/components/SearchResults';
import { subscribeWithSelector } from 'zustand/middleware';
import { toggleFacetOption } from '../filters/utils';

export const useProductsStore = create(
    subscribeWithSelector<ProductStoreStore>((set, get) => ({
        pagination: {
            type: 'page',
            page: 0,
            totalPages: 0,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        },
        setPagination: (pagination: Pagination) => {
            set(() => ({
                pagination,
            }));
        },
        products: [],
        setProducts: (products: Product[]) => {
            set(() => ({
                products,
            }));
        },
        appendProducts: (appendedProducts: Product[]) => {
            const products = [...get().products, ...appendedProducts];
            set(() => ({
                products,
            }));
        },
        prependProducts: (prependedProducts: Product[]) => {
            const products = [...prependedProducts, ...get().products];
            set(() => ({
                products,
            }));
        },
        totalProducts: 0,
        setTotalProducts: (totalProducts: number) => {
            set(() => ({
                totalProducts,
            }));
        },
        isSearching: true,
        setIsSearching: (isSearching: boolean) => {
            set(() => ({
                isSearching,
            }));
        },
        facets: [],
        setFacets: (facets: Facet[]) => {
            set(() => ({
                facets,
            }));
        },
        facetButtons: [],
        setFacetButtons: (facets: string[]) => {
            set(() => ({
                facetButtons: facets,
            }));
        },
        hiddenFacets: [],
        setHiddenFacets: (facets: string[]) => {
            set(() => ({
                hiddenFacets: facets,
            }));
        },
        searchSidebarFacet: undefined,
        setSearchSidebarFacet: (searchSidebarFacet: Facet | undefined) => {
            set(() => ({
                searchSidebarFacet,
            }));
        },
        selectedFacets: [],
        setSelectedFacets: (selectedFacets: { attribute: string; items: DropdownItem[] }[]) => {
            set(() => ({
                selectedFacets,
            }));
        },
        sortOptions: [],
        setSortOptions: (sortOptions: SortType[]) => {
            set(() => ({
                sortOptions,
            }));
        },
        selectedSort: undefined,
        setSelectedSort: (selectedSort: SortType | undefined) => {
            set(() => ({
                selectedSort,
            }));
            get().setPagination({
                ...get().pagination,
                page: 0,
            });
        },
        // #todo check if we can merge with selectedSort
        selectedFacetsSort: {
            sortKey: null,
            sortOrder: null,
        },
        setSelectedFacetsSort: (selectedFacetsSort: SortFacetsType) => {
            set(() => ({
                selectedFacetsSort,
            }));
            get().setPagination({
                ...get().pagination,
                page: 0,
            });
        },
        showFiltersDrawer: false,
        setShowFiltersDrawer: (showValue: boolean) => {
            set(() => ({
                showFiltersDrawer: showValue,
            }));
        },
        // activeCategories refers to the scoping of the entire PLP module to a selected category.
        activeCategories: [],
        setActiveCategories: (categoryId) => {
            set(() => ({
                activeCategories: categoryId,
            }));
            get().setPagination({
                ...get().pagination,
                page: 0,
            });
        },
        // SelectedCategories refers to the category filter options on the PLP module.
        selectedCategories: [] as string[],
        setSelectedCategories: (categories: string[]) => {
            set(() => ({
                selectedCategories: categories,
            }));
            get().setPagination({
                ...get().pagination,
                page: 0,
            });
        },
        availableCategories: {
            l1: [],
            l2: [],
            allCategories: [],
        },
        setAvailableCategories: (categories) => {
            set(() => ({
                availableCategories: categories,
            }));
        },
        setSelectOptions: (attribute: string, items: DropdownItem[]) => {
            const newSelectedFacets = [...get().selectedFacets];
            const facetIndex = newSelectedFacets.findIndex(
                (selectedFacet) => selectedFacet.attribute === attribute,
            );
            if (facetIndex > -1) {
                newSelectedFacets[facetIndex].items = items;
            } else {
                newSelectedFacets.push({
                    attribute: attribute,
                    items: items,
                });
            }
            get().setPagination({
                ...get().pagination,
                page: 0,
            });
            get().setSelectedFacets(newSelectedFacets);
        },
        toggleFacetOption: (attribute: string, newItem: DropdownItem) => {
            const newSelectedFacets = toggleFacetOption(
                [...get().selectedFacets],
                newItem,
                attribute,
            );
            get().setPagination({
                ...get().pagination,
                page: 0,
            });

            get().setSelectedFacets(newSelectedFacets);
        },
        toggleBooleanFacetOption: (attribute: string, value: boolean) => {
            let newSelectedFacets = [...get().selectedFacets];

            if (value == false) {
                newSelectedFacets = newSelectedFacets.filter((facet) => {
                    return facet.attribute != attribute;
                });
            } else {
                newSelectedFacets.push({
                    attribute: attribute,
                    items: [
                        {
                            label: '',
                            value: value ? 'True' : 'False', // Value is casted to account for string literal values of booleans
                        },
                    ],
                });
            }
            get().setPagination({
                ...get().pagination,
                page: 0,
            });
            get().setSelectedFacets(newSelectedFacets);
        },
        removeFacetOption: (attribute: string, newItem: DropdownItem) => {
            const newSelectedFacets = [...get().selectedFacets];
            const facetIndex = newSelectedFacets.findIndex(
                (selectedFacet) => selectedFacet.attribute === attribute,
            );
            if (facetIndex > -1) {
                const optionSelectedIndex = newSelectedFacets[facetIndex]?.items.findIndex(
                    (item) => item.value == newItem.value,
                );
                if (optionSelectedIndex > -1) {
                    const newItems = [...newSelectedFacets[facetIndex].items];
                    newItems.splice(optionSelectedIndex, 1);
                    newSelectedFacets[facetIndex].items = newItems;
                }

                get().setPagination({
                    ...get().pagination,
                    page: 0,
                });
                get().setSelectedFacets(
                    newSelectedFacets.filter((facet) => facet.items.length > 0),
                );
            }
        },
        removeFacetSelection: (attribute: string) => {
            const newSelectedFacets = [...get().selectedFacets];
            const facetIndex = newSelectedFacets.findIndex(
                (selectedFacet) => selectedFacet.attribute === attribute,
            );
            if (facetIndex > -1) {
                newSelectedFacets.splice(facetIndex, 1);
                get().setPagination({
                    ...get().pagination,
                    page: 0,
                });
                get().setSelectedFacets(newSelectedFacets);
            }
        },
        clearAllFacets: () => {
            get().setSelectedFacets([]);
            get().setPagination({
                ...get().pagination,
                page: 0,
            });
        },
        getSelectedFilterCount: (facet: Facet) => {
            const selected = get().selectedFacets?.find(
                (selectedFacet) => selectedFacet.attribute === facet.attribute,
            );
            return selected?.items.length ? selected.items.length : 0;
        },
        isNoResults: () => {
            return !!(get().pagination.totalPages == 0 && get().isSearching == false);
        },
        contentResults: [] as RelewiseContentResult[],
        setContentResults: (results: RelewiseContentResult[]) => {
            get().contentResults = results;
        },
        hasAnyResults: () => {
            return get().contentResults.length > 0 || get().totalProducts > 0;
        },
        showLocalePrices: null,
        setShowLocalePrices: (value: boolean) => {
            set(() => ({
                showLocalePrices: value,
            }));
        },
    })),
);
